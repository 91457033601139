import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import "./Phrase.module.css";

const Phrase = () => {
    const [phrase, setPhrase] = useState("");

    const getPhrase = async () => await supabase.from("phrases")
        .select()
        .then(({ data } ) => setPhrase(data![0].title));

    useEffect(() => {
        getPhrase();
    }, []);

    return (
        <h3>{ phrase }</h3>
    );
}

export default Phrase;
