import React from "react";
import avatar from "./nanda.jpg";
import "./Avatar.module.css";

const Avatar = () => {

    return (
        <img src={avatar} alt="Foto da Dra. Fernanda Caruso Guedes, sorrindo." />
    )
};

export default Avatar;
