import React, { FormEvent, useState } from "react";
import { supabase } from "../supabaseClient";
import "./Input.module.css";

const InputPhrase = () => {

    const [title, setTitle] = useState("");

    const handleChange = (e: any) => {
        setTitle(e?.target?.value);
    }

    const handleSubmit = async (e: FormEvent) => {
        e?.preventDefault();
        const { error } = await supabase.from('phrases')
            .update({ title }) 
            .eq('id', 1);

        if(error) alert(error);
        else alert("Update realizado com sucesso.")
    }

    return (
        <>
            <p>Oi Nanda! Pode escrever uma nova frase aqui em baixo:</p>
            <form onSubmit={(e) => handleSubmit(e)}>
                <textarea name="phrase" rows={5} onChange={handleChange} />
                <button type="submit">Manda!</button>
            </form>
        </>
    );
}

export default InputPhrase;