import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./Home/Home";
import Input from "./Input/Input";
import "./App.css";

const App = () => {

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route index element={<Home />} />
                    <Route path="/admin" element={<Input />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
