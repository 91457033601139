import React from "react";
import Avatar from "../Avatar/Avatar";
import Phrase from "../Phrase/Phrase";

const Home = () => {
    return (
        <div className="App-home">
            <Avatar />
            <Phrase />
        </div>
    )
}

export default Home;